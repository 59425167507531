import React from 'react';
import { withRouter } from "react-router-dom";
import {Table, Card, Container, Row, Col, Button, Form} from 'react-bootstrap';
import {SearchEndpoint} from '../../api/ApiUtils';
import COMMON_CRYPTOS from '../../assets/commonCryptos';
import '../../assets/horizontalScroll.css'
import GuageMeter from './guageMeter';
import NewsView from './newsView';
const natural = require("natural");
const stopword = require("stopword");


class LearnRegisterForm extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        error: '',
        errorMessage: '',
        tokenName: '',
        newsItems: [],
        analysed_news_feed_array: [],
        negative_records: 0,
	      neutral_records: 0,
	      positive_records: 0,
        isLoading: false,
        hideGuage: true,
        cardView: false
      };
    }
 
    // Contractions to standard lexicons Conversion
 convertToStandard = text => {
    const wordDict = {
        "aren't": "are not",
        "can't": "cannot",
        "couldn't": "could not",
        "didn't": "did not",
        "doesn't": "does not",
        "don't": "do not",
        "hadn't": "had not",
        "hasn't": "has not",
        "haven't": "have not",
        "he'd": "he would",
        "he'll": "he will",
        "he's": "he is",
        "i'd": "I would",
        "i'd": "I had",
        "i'll": "I will",
        "i'm": "I am",
        "isn't": "is not",
        "it's": "it is",
        "it'll": "it will",
        "i've": "I have",
        "let's": "let us",
        "mightn't": "might not",
        "mustn't": "must not",
        "shan't": "shall not",
        "she'd": "she would",
        "she'll": "she will",
        "she's": "she is",
        "shouldn't": "should not",
        "that's": "that is",
        "there's": "there is",
        "they'd": "they would",
        "they'll": "they will",
        "they're": "they are",
        "they've": "they have",
        "we'd": "we would",
        "we're": "we are",
        "weren't": "were not",
        "we've": "we have",
        "what'll": "what will",
        "what're": "what are",
        "what's": "what is",
        "what've": "what have",
        "where's": "where is",
        "who'd": "who would",
        "who'll": "who will",
        "who're": "who are",
        "who's": "who is",
        "who've": "who have",
        "won't": "will not",
        "wouldn't": "would not",
        "you'd": "you would",
        "you'll": "you will",
        "you're": "you are",
        "you've": "you have",
        "'re": " are",
        "wasn't": "was not",
        "we'll": " will",
        "didn't": "did not"
    }
	const data = text.split(' ');
	data.forEach((word, index) => {
		Object.keys(wordDict).forEach(key => {
			if (key === word.toLowerCase()) {
				data[index] = wordDict[key]
			};
		});
	});

	return data.join(' ');
}

// LowerCase Conversion
 convertTolowerCase = text => {
	return text.toLowerCase();
}

// Pure Alphabets extraction
 removeNonAlpha = text => {

	// This specific Regex means that replace all
	//non alphabets with empty string.
	return text.replace(/[^a-zA-Z\s]+/g, '');
}

componentDidMount(){
  const asset_path_name = window.location.pathname;
  let asset_symbol = asset_path_name.split("/")[1]
  let partOfPath_two = asset_path_name.split("/")[2]

  if(partOfPath_two == 'sentiment-analysis') {
    this.state.tokenName =  asset_symbol
  this.handleSubmit()
  }

}


    handleSubmit = async (e) => {
      e?.preventDefault();
      this.props.history.push(`/${this.state.tokenName}/sentiment-analysis`)
      console.log("submmiting request")
     this.setState({
        analysed_news_feed_array: [],
        negative_records: 0,
	    neutral_records: 0,
	    positive_records: 0,
        isLoading: true
    })
      console.log(this.state)
      const model = "news"
      const term =this.state.tokenName
      const limit = 100
      const pageNo = 0
      const response = await SearchEndpoint(model, term, limit, pageNo)
      // For conversion of contractions to standard lexicon

      this.setState({newsItems: response.data.data.news_feeds})

      console.log(this.state.newsItems);
      //go through the data  one by one pushing them into analyzed array
	
	for (var i = 0; i < this.state.newsItems.length; i++) {
		
		// NLP Logic
		// Convert all data to its standard form
		const lexData = this.convertToStandard(this.state.newsItems[i].title);
		// console.log("Lexed Data: ", lexData);

		// Convert all data to lowercase
		const lowerCaseData = this.convertTolowerCase(lexData);
		// console.log("LowerCase Format: ", lowerCaseData);

		// Remove non alphabets and special characters
		const onlyAlpha = this.removeNonAlpha(lowerCaseData);
		// console.log("OnlyAlpha: ", onlyAlpha);

		// Tokenization
		const tokenConstructor = new natural.WordTokenizer();
		const tokenizedData = tokenConstructor.tokenize(onlyAlpha);
		// console.log("Tokenized Data: ", tokenizedData);

		// Remove Stopwords
		const filteredData = stopword.removeStopwords(tokenizedData);
		// console.log("After removing stopwords: ", filteredData);

		// Stemming
		const Sentianalyzer =
			new natural.SentimentAnalyzer('English', natural.PorterStemmer, 'afinn');
		const analysis_score = Sentianalyzer.getSentiment(filteredData);
		// console.log("Sentiment Score: ", analysis_score);

		//push the result to analysed news feed
		this.state.newsItems[i].sentiment_score = analysis_score
		this.state.analysed_news_feed_array.push(this.state.newsItems[i])
	

		if(analysis_score > 0){
				this.state.positive_records += 1 
			}else if(analysis_score === 0){
				this.state.neutral_records +=1
			}else{
				this.state.negative_records +=1
			}
	}
    this.setState({
      analysed_news_feed_array: this.state.analysed_news_feed_array,
      isLoading: false,
      hideGuage: false
    })
  }

  //handle input change
  handleInputChange = (event) => {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;
    console.log(target.value)
    if (inputName == 'consent') {
      this.setState({
        [inputName]: !this.state.consent
      });
    } else {

      this.setState({
        [inputName]: inputValue
      });
    }


  }

  //handle cardview
  handleCardView = () => {
this.setState({cardView: !this.state.cardView})
console.log(this.state.cardView);
  }

 render() {
    const analyzedNewsFeed = this.state.analysed_news_feed_array
    const currentHref = window.location.href;


    return (
      <>
        <br />
        <h1>{this.state.tokenName?this.state.tokenName:"Crypto Market"} Sentiment Analysis</h1>
        <Row className="mt-5 shadow-lg p-3">
       <Col>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <h2>Input the token name/symbol here.</h2>
          </Row>
          <Form.Group as={Col} controlId="formGridFname">
            <Form.Label>Token Name/Symbol</Form.Label>
            <Form.Control 
              type="text" 
              name="tokenName"
              onChange={(e) => this.handleInputChange(e)}
              placeholder="Token name/symbol e.g Cardano or $ADA" />
          </Form.Group>
      
          <div className="d-grid gap-2">
        <Button variant="success" disabled={this.state.isLoading} type="submit" size="lg" className="mt-5">
          {this.state.isLoading ? 'Loading…' : 'Submit'}
        </Button>
        </div>
      </Form>
      <span>Common cryptos <br/></span>
      <div className='w-80'>

      {COMMON_CRYPTOS.COMMON_CRYPTOS_ARRAY().map((crypto,i) => {
        return(
          <span className='p-2'>
            <a href={`/${crypto}/sentiment-analysis`}>{crypto}</a>
            {i===3?<br/>:''}
          </span>
        )
        
      })}
      </div>
        </Col> 
        <Col className='mt-5'>
        <div className='beforeSearch' hidden={!this.state.hideGuage}>
        <h2 className='mb-3'>Sentiment results will appear here</h2>
        <img src="https://static.thenounproject.com/png/1746820-200.png" class="img-fluid" alt="Responsive image"/>
              <span>Search for your results to appear here.</span>
        </div>
        <div className='afterSearch' hidden={this.state.hideGuage}>
        <h2 className='mb-3'>Sentiment results for {this.state.tokenName}</h2>
        <GuageMeter
            tokenName = {this.state.tokenName? this.state.tokenName : "Crypto Market"}
            negative = {this.state.negative_records} 
            neutral = {this.state.neutral_records}
            positive = {this.state.positive_records}/>
        </div>
        </Col>
        </Row>
        <Row>
        <div class="mt-5 shadow-lg p-3">
                <span class="" >This is Athena. Athena is a project by Coinjupiter to help you analyze the news.
                    Athena picks news items based on the filters that you select and makes an analysis on the same.
                    The result is then displayed on the right for you. Whatever you intend to do with the rersults is up to you.
                    <br/><br/>DISCLAIMER: Information that you get on this site is based on third party sources. 
                    We will not be held accountable in any way for the results based on information got from this site.
                    <hr/><br/>
                    <span class="h5 d-none d-lg-block">Support us ($ETH, $BNB, $AVAX): 0x59C1876B356DB9daD73d41aA9114E2aBBa471378</span>
                  </span>
              </div>
        </Row>

        <NewsView
          handleCardView={this.handleCardView}
          cardView={this.state.cardView}
          analyzedNewsFeed={analyzedNewsFeed}
          hideGuage = {this.state.hideGuage}
        />

       </> 
    );
 }
  }

export default withRouter(LearnRegisterForm)


