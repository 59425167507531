import React from 'react';
// import {Nav, Navbar, Container} from 'react-bootstrap'
// import GLOBAL_STRINGS from '../assets/strings';
import Adshere from '../../../assets/img/adverthere.png'

function HorizontalBanner(props) {
    return (
        <div className="banner mt-3">
            <div className="d-xsm-block">
                <a href="https://coindiskus.com/signup" target="_blank" className="me-4 text-reset">
                    <img src={Adshere} style={{ width: "100%", height: "auto" }} alt="Adimage" />
                </a>
            </div>
        </div>
    );
}


export default HorizontalBanner