import React, { Component } from "react";
import { Route } from "react-router-dom";

import { BrowserRouter, Redirect, Switch } from "react-router-dom";


import Home from "./pages/index";
import News from "./pages/news";
import Glossary from "./pages/glossary";
import Exchanges from "./pages/exchanges";
import Learn from "./pages/learn";
import ComingSoon from "./pages/comingSoon";
import SingleCoinPage from "./pages/singleCoinPage";
import SingleNewsitemPage from "./pages/SingleNewsitemPage"
import SingleGlossaryPage from "./pages/singleGlossaryPage"
import InvestmentCalc from "./pages/investmentCalculator"
class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/:tokenname/sentiment-analysis" component={Home} />
            <Route exact path="/news" component={News} />
            <Route exact path="/glossary" component={Glossary} />
            <Route exact path="/exchanges" component={Exchanges} />
            <Route exact path="/learn" component={Learn} />
            <Route exact path="/investmentcalc" component={InvestmentCalc} />
            <Route exact path="/comingsoon" component={ComingSoon} />
            <Route exact path="/assets/:assetname" component={SingleCoinPage} />
            <Route exact path="/newsitem/:urlslug" component={SingleNewsitemPage} />
            <Route exact path="/glossary/:urlslug" component={SingleGlossaryPage} />
           
        
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
    );
  }
}

export default App;