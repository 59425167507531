const COMMON_CRYPTOS = {

    
    COMMON_CRYPTOS_ARRAY: () => {
        return ["Bitcoin","Ethereum","Shiba","Cardano","Avax","Filecoin","Solana","Doge"];
    }

 }

 module.exports = COMMON_CRYPTOS

