import React, { createRef, useState } from 'react';
import { TwitterShareButton, TwitterIcon, RedditShareButton, RedditIcon } from 'react-share';
import GaugeChart from 'react-gauge-chart';

// import {Nav, Navbar, Container} from 'react-bootstrap'
// import GLOBAL_STRINGS from '../assets/strings';
// import '../../assets/guage.css'


function GuageMeter(props) {
  var positive = Math.round((props.positive/(props.positive+props.negative))*100)
  var negative = Math.round((props.negative/(props.positive+props.negative))*100)

  return (

    <div>
      <GaugeChart id="gauge-chart3"
        nrOfLevels={30}
        colors={['#EA4228', '#F5CD19', '#5BE12C']}
        arcWidth={0.099}
        percent={positive/100}
        textColor={'#000'}
      />
      <div className='guageinfo'>
        <span className="h4 lable row ml-4">Positivity Meter!</span>

        <div className="badges row">
          <span className="positiveBadge btn btn-success col">{`${positive}% `}+Ve</span>
          {/* <span className="neutralBadge btn btn-warning col">{`${props.neutral}%`}neutral</span> */}
          <span className="negativeBadge btn btn-danger col">{`${negative}%`}-ve</span>
        </div>

        <div className="row shareButtons mt-5">
       
          <div className="col" >
            <TwitterShareButton
              title={`${props.tokenName} is ${positive}% positive on sentimental analysis based on news.`}
              via={`coinjupiter`}
              url={`https://sentiment.coinjupiter.com/`}
              children={
                <TwitterIcon size={60} round={true} />
              }
            />
            <RedditShareButton
              title={`${props.tokenName} is ${positive}% positive on sentimental analysis based on news.`}
              url={`https://sentiment.coinjupiter.com/`}
              children={
                <RedditIcon size={60} round={true} />
              }
            />
          </div>
        </div>
      </div>

    </div>




  );
}


export default GuageMeter