import React, { createRef, useState } from 'react';
import { Table, Card, Row, Col, Button, Form } from 'react-bootstrap';
import { TwitterShareButton, TwitterIcon, RedditShareButton, RedditIcon } from 'react-share';

function NewsView(props) {

  return (
    <>
      <Row className='mt-5' hidden={props.hideGuage}>
        <div>Analysed Results</div>
        <Button onClick={props.handleCardView}>Toogle view </Button>
        {props.cardView ?
          <Row className='mt-5'>
            {props.analyzedNewsFeed.map((newsItem, i) => {

              return (
                <Col className='mb-5'>
                  <Card style={{ width: '18rem', height: "100%" }}>
                    <Card.Img variant="top" src={newsItem.news_room_logo_url} />
                    <Card.Body>
                      <Card.Title>{newsItem.title}</Card.Title>
                      {/* <Card.Text tyle={{display: '-webkit-box',width: '100%', 
                    overFlow: 'hidden', 
                    textOverflow: 'ellipsis',
                    webkitLineClamp: '2',
                    webkitBoxOrient: 'vertical'        }}>
                      {newsItem.body}
                    </Card.Text> */}
                      <Card.Link href={newsItem.news_item_url}>Visit {newsItem.news_room_name}</Card.Link>
                      <div className="row shareButtons mt-5">

                        <div className="col" >
                          <TwitterShareButton
                            title={newsItem.title}
                            via={`coinjupiter`}
                            url={`https://sentiment.coinjupiter.com/`}
                            children={
                              <TwitterIcon size={40} round={true} />
                            }
                          />
                          <RedditShareButton
                            title={newsItem.title}
                            url={`https://sentiment.coinjupiter.com/`}
                            children={
                              <RedditIcon size={40} round={true} />
                            }
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )

            })}
          </Row>
          :
          <Table hover responsive>
            <thead>
              <tr>
                <th>Heading</th>
                <th>Grade</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {props.analyzedNewsFeed.map((newsItem, i) => {
                // console.log(newsItem);
                let ChangeClass;
                let SortClass;
                newsItem.RAW?.USD.CHANGEPCT24HOUR > 0 ? ChangeClass = "text-success" : ChangeClass = "text-danger";
                newsItem.RAW?.USD.CHANGEPCT24HOUR > 0 ? SortClass = "fas fa-sort-up" : SortClass = "fas fa-sort-down";

                return (
                  <tr key={i}>
                    <td>{newsItem.title}</td>
                    <td>{newsItem.sentiment_score}</td>
                    <td> <a href={newsItem.news_item_url}>Visit {newsItem.news_room_name}</a></td>
                  </tr>
                )
              })}

            </tbody>
          </Table>
        }
      </Row>

    </>


  );
}


export default NewsView