import Container from 'react-bootstrap/Container'

import Header from '../components/header'
import Footer from '../components/footer'
// import Sentiments from '../components/sentimentsAnalyzer/functionalIndex'
import Sentiments from '../components/sentimentsAnalyzer'


function App() {
  
  return (
    <div className="App">
      <Header />

       <Container className="pt-5">
          <Sentiments />
       </Container>
        
     <Footer />
    </div>
  );
}

export default App;



