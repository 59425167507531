import React from 'react';
import GLOBAL_STRINGS from '../assets/strings';

function Footer (props) {
    return (
        
        <footer className="text-center text-lg-start bg-light text-muted">
          
         
        
          
          <section className="pt-1 mt-5">
            <div className="container text-center text-md-start mt-5">
              
              <div className="row mt-3">
                
                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                  
                  <h6 className="text-uppercase fw-bold mb-4">
                    <i className="fas fa-gem me-3"></i>{GLOBAL_STRINGS.COMPANY_NAME()}
                  </h6>
                  <p>
                    {GLOBAL_STRINGS.COMPANY_DESCRIPTION()}
                  </p>
                  <a href="https://nomics.com/" className="text-reset">Crypto Market Cap & Pricing Data Provided By Nomics</a>
                  <hr/>
                  <a href="https://www.cryptocompare.com/" className="text-reset">Data supplemented by Cryptocompare</a>
                </div>
                
        
                
                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  
                  <h6 className="text-uppercase fw-bold mb-4">
                    Support
                  </h6>
                  <p>
                    <a href="#!" className="text-reset">Request Form</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset">Contact Support</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset">FAQ</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset">Glossary</a>
                  </p>
                </div>
                
        
                
                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                  
                  <h6 className="text-uppercase fw-bold mb-4">
                    Useful links
                  </h6>
                  <p>
                    <a href="/exchanges" className="text-reset">Exchanges</a>
                   
                  </p>
                  <p>
                    <a href="/news" className="text-reset">News</a>
                  </p>
                  <p>
                    <a href="/learn" className="text-reset">Learn</a>
                  </p>
                  <p>
                    <a href="/" className="text-reset">Coinjupiter</a>
                  </p>
                </div>
                
        
                
                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                  
                  <h6 className="text-uppercase fw-bold mb-4">
                  <span>{GLOBAL_STRINGS.SOCIAL_NETWORK_CONNECT_PHRASE()}</span>
                </h6>
                
                <p> <a href="https://twitter.com/coinjupiter" className="me-4 text-reset">
                <i className="fab fa-twitter"></i> Twitter
                </a></p>
                
                <p> <a href="https://t.me/joinchat/1dMcUkcUa_FkNzE0" className="me-4 text-reset">
                  <i className="fab fa-telegram"></i> Telegram
                </a></p>
                  
                </div>
                
              </div>
              
            </div>
          </section> 
          
          <div className="text-center p-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
            © 2021 Copyright:
            <a className="text-reset fw-bold" href="#">{GLOBAL_STRINGS.COMPANY_NAME()}</a>
          </div>
          
        </footer>
        
    );
  }


export default Footer