import React from 'react';
import {Nav, Navbar, Container} from 'react-bootstrap'
import GLOBAL_STRINGS from '../assets/strings';
import Logo from '../assets/img/logo.jpg'

function Header(props) {
  return (
    <div>
      
        <Navbar collapseOnSelect fixed='top' expand='md' bg='dark' variant='dark'>
        <Navbar.Brand href="/home" className="d-none d-md-block pl-3">
        {/* <img
        alt=""
        src={Logo}
        width="30"
        height="30"
        className="d-inline-block align-top"
      />{' '} */}
          {GLOBAL_STRINGS.COMPANY_NAME()}
          </Navbar.Brand>
          <Container className="content-right">
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav>
                  <Nav.Link href="/home">Home</Nav.Link>
                  <Nav.Link href="https://coinjupiter.com/news">News</Nav.Link>
                  <Nav.Link href="https://coinjupiter.com/glossary">Glossary</Nav.Link>
                  {/* <Nav.Link href="/exchanges">Exchanges</Nav.Link> */}
                  {/* <Nav.Link href="https://sentiment.coinjupiter.com">Crypto Sentiments</Nav.Link> */}
                  {/* <Nav.Link href="/investmentcalc">Investment Calculator</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }


export default Header