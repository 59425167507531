const GLOBAL_STRINGS = {

    
    COMPANY_NAME: () => {
        return "Athena";
    },

    COMPANY_DESCRIPTION: () => {
        return `This is Athena. Athena is a project by Coinjupiter to help you analyze the news.
        Athena picks news items based on the filters that you select and makes an analysis on the same.
        The result is then displayed on the right for you. Whatever you intend to do with the rersults is up to you.
        <br><br>DISCLAIMER: Information that you get on this site is based on third party sources. 
        We will not be held accountable in any way for the results based on information got from this site.`;
    },

    COMPANY_EMAIL: () => {
        return `CoinJupiter@gmail.com`;
    },

    COMPANY_PHONE_NUMBER: () => {
        return `+254799667788`;
    },

    //function to update the coporate user
    COMPANY_ADDRESS: () => {
        return `44 Kimathi street, Nairobi, Kenya`;
    },
    SOCIAL_NETWORK_CONNECT_PHRASE: () => {
        return `Socials:`
    }

 }

 module.exports = GLOBAL_STRINGS

